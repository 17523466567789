import React from "react";

import HTMLReactParser from "html-react-parser";

import { TextContainer } from "./TextBlock.styles";

const TextBlock = (props) => {
  //PROPS
  const {
    data: { title, text }
  } = props;
  //PROPS

  return (
    <TextContainer white={props.white}>
      {title && <h3>{title}</h3>}
      <div
        className={
          props.isBig
            ? "container container--800"
            : "container container--xsmall"
        }
      >
        {props.isBig}
        {text && HTMLReactParser(text)}
      </div>
    </TextContainer>
  );
};

export default TextBlock;
