import styled from "styled-components";

import { white } from "theme/colors";
import { toRem } from "utils/mixins";

export const TextContainer = styled.div`
  z-index: 2;
  position: relative;
  color: ${(props) => (props.white ? white.default : "inherit")};
  h3 {
    text-align: center;
    color: ${(props) => (props.white ? white.default : "inherit")};
    margin-bottom: ${toRem(20)};
  }
`;
