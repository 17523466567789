import React, { useEffect } from "react";

import styled from "styled-components";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import { black } from "theme/colors";

import TextBlock from "components/textBlock";
import Submenu from "components/submenu";

const ShootingInPortugalTemplate = (props) => {
  //PROPS
  const {
    data: {
      page: {
        id,
        shooting_portugal_page: { titleText, imageBackground }
      }
    },
    pageContext: {
      language: { code }
    },
    isBlack,
    setBlack,
    locale
  } = props;
  //PROPS

  //EFFECT
  useEffect(() => {
    setBlack(false);
    return () => {
      setBlack(true);
    };
  }, [setBlack]);
  //EFFECT

  return (
    <>
      <main className="main main--pad main--flex container">
        <h1 className="abs_hide">
          {locale === "EN" ? "Shooting in Portugal" : "Filmar em Portugal"}
        </h1>
        <Submenu isBlack={isBlack} code={code} page={id} white />

        <Bg>
          {imageBackground && (
            <img
              alt={imageBackground.altText}
              src={imageBackground.localFile.childImageSharp.fluid.src}
              srcSet={imageBackground.localFile.childImageSharp.fluid.srcset}
            />
          )}
        </Bg>
        <div className="spt">
          {titleText &&
            titleText.map((item, index) => {
              return <TextBlock data={item} key={index} white isBig />;
            })}
        </div>
      </main>
    </>
  );
};

const Bg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  background: ${black.default};
  /* div {
    width: 100vw !important;
    max-width: 100vw;
    height: 100vh !important;
    max-height: 100vh;
  } */
  img {
    z-index: 2;
    display: block;
    width: 100vw !important;
    max-width: 100vw;
    height: 100vh !important;
    max-height: 100vh;
    object-position: bottom !important;
    object-fit: cover !important;
    opacity: 0.8;
  }
`;

export const query = graphql`
  query Shoot($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      shooting_portugal_page {
        titleText {
          text
          title
        }
        backgroundMediaType
        imageBackground {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 3600, quality: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default ShootingInPortugalTemplate;
